<!--
 * @Author: your name
 * @Date: 2021-04-19 18:30:28
 * @LastEditTime: 2021-05-19 14:58:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\sub\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation>
      <!-- <span @click="showshezhi = true" class="sz">设置</span> -->
      <template>
        <div @click.stop="seefile" class="fujianall">
          <van-icon size=".4rem" color="#ff6d20" name="description" />
          <div class="fujianclass">查看附件</div>
        </div>
      </template>
    </navigation>
    <div class="tuijclass">
      推荐公司：{{
        allmsg.company && allmsg.company.length > 5 && isshare
          ? allmsg.company.substr(0, 3) +
            new Array(allmsg.company.length - 4).join("*") +
            allmsg.company.substr(-3, 2)
          : allmsg.company
      }}
    </div>
    <div class="mmc">
      <div class="classmsg">
        <div>
          <div class="pms">
            <div>{{ allmsg.name }}</div>
            <img
              class="pms_2"
              v-if="(allmsg.sex + '').indexOf('男') != -1"
              src="../../assets/sex_1.png"
              alt=""
            />
            <img
              class="pms_2"
              v-if="(allmsg.sex + '').indexOf('女') != -1"
              src="../../assets/sex_2.png"
              alt=""
            />
            <div class="pms_1" v-if="allmsg.politics">
              {{ allmsg.politics }}
            </div>
          </div>
          <div v-if="allmsg.u_time" class="gxtime">
            {{ allmsg.u_time ? "更新时间:" + allmsg.u_time.split(" ")[0] : "" }}
          </div>
          <div class="phmsg">
            <div>
              {{
                allmsg.job_status == 1
                  ? "离职 随时到岗"
                  : allmsg.job_status == 2
                  ? "在职-月内到岗"
                  : allmsg.job_status == 3
                  ? "在职-考虑机会"
                  : allmsg.job_status == 4
                  ? "在职，暂不考虑"
                  : "保密"
              }}
            </div>
            <div>
              手机:
              {{
                is_download == 10
                  ? allmsg.mobile
                  : (allmsg.mobile + "").substr(0, 3) +
                    "****" +
                    (allmsg.mobile + "").substr(7)
              }}
            </div>
          </div>
          <div class="imgcc">
            <div>
              <img src="../../assets/mm1.png" alt="" />{{
                allmsg.years_working
              }}年
            </div>
            <div>
              <img src="../../assets/mm2.png" alt="" />{{
                allmsg.education_background
              }}
            </div>
            <div><img src="../../assets/mm3.png" alt="" />{{ age }}</div>
          </div>
        </div>
        <div class="userclass">
          <img
            class="imgsex"
            v-if="(allmsg.sex + '').indexOf('男') != -1"
            src="../../assets/user_1.png"
            alt=""
          />
          <img
            class="imgsex"
            v-if="(allmsg.sex + '').indexOf('女') != -1"
            src="../../assets/user_2.png"
            alt=""
          />
          <img class="pms_3" src="../../assets/shiming.png" alt="" />
        </div>
      </div>
      <div class="henggang"></div>

      <div class="allgw">
        <div class="gw1">
          <div></div>
          <div>求职期望</div>
        </div>
        <div id="gw_2" class="gw2">
          <div class="gw2_1">{{ allmsg.jop }}，{{ allmsg.province }}</div>
        </div>
        <div class="moclaall">
          <div></div>
          <div class="mocla">{{ allmsg.salary }}</div>
        </div>
        <div class="gw3">期望行业：{{ allmsg.industry }}</div>
      </div>
      <div class="henggang"></div>
      <div v-if="qiyemsg != ''" class="alljingcla">
        <div class="gw1 jingl">
          <div></div>
          <div>工作经历</div>
        </div>
        <div>
          <div
            v-for="(item, index) in qiyemsg"
            v-bind:key="index"
            class="jingcla"
          >
            <div>
              <div>
                {{
                  item.company_name && item.company_name.length > 5 && isshare
                    ? item.company_name.substr(0, 3) +
                      new Array(item.company_name.length - 4).join("*") +
                      item.company_name.substr(-3, 2)
                    : item.company_name
                }}
              </div>
            </div>
            <div class="jltwo">
              <div>
                {{
                  item.position
                    ? String(item.position).replace(/\[|]/g, "")
                    : ""
                }}
              </div>
              <div class="timeclass">
                {{ item.entry_time }}至{{ item.quit_time }}
              </div>
            </div>

            <mytextarea
              :index="index"
              stylemsg="border:none;font-size: 0.28rem;font-weight: 400;color: #606266;line-height: 0.48rem;"
              :readonlymsg="true"
              :msg="item.working_experience.replace(/\[]/g, '\n')"
            ></mytextarea>
          </div>
        </div>
      </div>

      <div class="henggang"></div>

      <!-- <div v-if="allmsg.province != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>户籍所在地</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.household_register }}</div>
          <div class="mocla"></div>
        </div>
      </div> -->
      <!-- <div v-if="allmsg.email != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>联系邮箱</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">
            {{ allmsg.email == "" ? "无" : "****************" }}
          </div>
          <div class="mocla"></div>
        </div>
      </div> -->
      <!-- <div v-if="allmsg.industry != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>意向行业</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.industry }}</div>
          <div class="mocla"></div>
        </div>
      </div> -->

      <div v-if="educational_experience != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>教育经历</div>
        </div>
        <div class="scc">
          <div
            v-for="(item, index) in educational_experience"
            v-bind:key="index"
            class="jingcla"
          >
            <div>
              <div>{{ item.school }}</div>
            </div>
            <div class="timebackclass">
              <div>{{ item.education_background }} {{ item.major }}</div>
              <div v-if="item.start_time && item.end_time" class="timeclass">
                {{ item.start_time }}至{{ item.end_time }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="henggang"></div>
      <!-- <div v-if="allmsg.politics != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>政治面貌</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.politics }}</div>
          <div class="mocla"></div>
        </div>
      </div> -->
      <!-- <div v-if="allmsg.years_working != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>工作年限</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.years_working }}</div>
          <div class="mocla"></div>
        </div>
      </div> -->
      <!-- <div v-if="allmsg.province != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>求职区域</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">
            {{ allmsg.province }} {{ allmsg.city }} {{ allmsg.district }}
          </div>
          <div class="mocla"></div>
        </div>
      </div> -->

      <!-- <div v-if="allmsg.honor != ''" class="allgw">

        <div class="gw1">
          <div></div>
          <div>职业资格证书/荣誉奖项</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.honor }}</div>
          <div class="mocla"></div>
        </div>
      </div> -->

      <div class="gw1 jingl">
        <div></div>
        <div>技能云</div>
      </div>
      <div class="jin">
        <div @click="jimeth(0)" :class="jiindex == 0 ? 'selcl' : 'selcl2'">
          工作技能
        </div>
        <div @click="jimeth(1)" :class="jiindex == 1 ? 'selcl' : 'selcl2'">
          生活技能
        </div>
        <div @click="jimeth(2)" :class="jiindex == 2 ? 'selcl' : 'selcl2'">
          综合技能
        </div>
      </div>
      <div class="duana">
        <div v-show="skillindex == 0">
          <div
            class="duana2"
            v-if="skillList.work && skillList.work.length > 0"
          >
            <div
              :class="item.status == 1 ? 'ddiv1' : 'ddiv2'"
              v-for="(item, index) in skillList.work"
              v-bind:key="index"
            >
              {{ item.skill_name }}
            </div>
          </div>
          <div v-if="skillList.work && skillList.work.length < 1">
            <nomsgvue></nomsgvue>
          </div>
        </div>
        <div v-show="skillindex == 1">
          <div
            class="duana2"
            v-if="skillList.lift && skillList.lift.length > 0"
          >
            <div
              :class="item.status == 1 ? 'ddiv1' : 'ddiv2'"
              v-for="(item, index) in skillList.lift"
              v-bind:key="index"
            >
              {{ item.skill_name }}
            </div>
          </div>
          <div v-if="skillList.lift && skillList.lift.length < 1">
            <nomsgvue></nomsgvue>
          </div>
        </div>
        <div v-show="skillindex == 2">
          <div
            class="duana2"
            v-if="skillList.other && skillList.other.length > 0"
          >
            <div
              :class="item.status == 1 ? 'ddiv1' : 'ddiv2'"
              v-for="(item, index) in skillList.other"
              v-bind:key="index"
            >
              {{ item.skill_name }}
            </div>
          </div>
          <div v-if="skillList.other && skillList.other.length < 1">
            <nomsgvue></nomsgvue>
          </div>
        </div>
      </div>
      <div v-if="!isshare" class="faqi">
        <button @click="getmsgmeth">发起背调</button>
        <!-- <button @click="$toast('即将开放')">面试邀约</button> -->
        <button @click="toresumeEvaluation">能力分析</button>
        <button @click="ruzhimeth">邀请入职</button>
      </div>
      <div v-if="isshare" class="faqi3">
        <button @click="getmsgmeth2">获取联系方式</button>
      </div>
    </div>

    <div class="nomsgclass"></div>
    <van-action-sheet
      v-model="showshezhi"
      :actions="actions"
      cancel-text="取消"
      description="状态设置"
      close-on-click-action
      @select="selectshezhi"
    />
    <sharepage ref="sharitid"></sharepage>
    <share ref="shareid"></share>
    <!-- 悬浮窗 -->
    <!-- <div class="fx">
      <div @click="toresumeEvaluation">能力分析</div>
    </div> -->
  </div>
</template>
<script>
import sharepage from "../../views/sharepage/index";
import share from "../../components/share/index";
import { Dialog } from "vant";
import nomsgvue from "../../components/nomsg/index";
import share_resume from "../../../util/share_resume";
import getage from "../../../util/age";
import islogin from "../../../util/islogin";
import base from "../../../util/base";
import resumelocal from "../../../util/resumelocal";

export default {
  components: {
    nomsgvue,
    sharepage,
    share,
  },
  data() {
    return {
      jiindex: "0",
      showshezhi: false,
      actions: [{ name: "入职" }],
      allmsg: "", //数据
      skillList: "", //工作技能
      skillindex: 0, //工作技能下标
      qiyemsg: "",
      age: "", //年龄
      educational_experience: "",
      share_phone: "",
      isshare: false, //是否是分享进入
      is_download: "", //是否是下载的简历
    };
  },
  created() {
    let sharemm = JSON.parse(sessionStorage.getItem("sharemsg"));
    if (sharemm != "" && sharemm != undefined && sharemm.urlsharees) {
      this.$route.query.id = sharemm.id;
      this.$route.query.is_download = 0;
      this.share_phone = sharemm.share_phone;
      this.isshare = true;
    } else {
    }
    this.clickmeth(); //分享功能
    this.$http
      .post("/firm/v1/resume/details", {
        reqType: "resume",
        report_id: atob(this.$route.query.id),
      })
      .then((res) => {
        this.allmsg = JSON.parse(res.data).data.resumeDetail;
        console.log(this.allmsg)
        this.skillList = JSON.parse(res.data).data.skillList;
        if (this.allmsg.work_resume != "") {
          this.qiyemsg = JSON.parse(this.allmsg.work_resume);
        } else {
          this.qiyemsg = "";
        }

        //该简历是否下载
        if (this.allmsg.is_download) {
          this.is_download = this.allmsg.is_download;
        }

        //教育经历
        if (this.allmsg.educational_experience != "") {
          this.educational_experience = JSON.parse(
            this.allmsg.educational_experience
          );
        } else {
          this.educational_experience = "";
        }
        //年龄
        this.age = getage(this.allmsg.birthday, this.allmsg.age);
      });
  },
  methods: {
    toresumeEvaluation() {
      this.$router.push({
        path: "resumeEvaluation",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    /**
     * 查看简历
     */
    seefile() {
      if (this.allmsg.resume) {
        window.location.href = this.allmsg.resume;
      } else {
        this.$toast("该简历无附件");
      }
    },

    /**
     * 点击去邀请
     */
    clickmeth() {
      if (this.allmsg) {
        resumelocal(this.allmsg, this.$route);
      }
    },
    ruzhimeth() {
      Dialog.confirm({
        title: "提示",
        message:
          "确认入职，会给候选员工发送确认短信，完成确认后就可以开始职业档案管理啦。",
      })
        .then(() => {
          this.ruzhimeth2();
        })
        .catch(() => {
          // on cancel
        });
    },
    /**
     * 入职
     */
    ruzhimeth2() {
      this.$http
        .post("/firm/v1/resume/create_employee_files", {
          reqType: "resume",
          l_id: this.allmsg.id,
          status: 2, //入职
        })
        .then((res) => {
          this.$toast(JSON.parse(res.data).msg);
        });
    },
    /**
     * 获取联系方式
     */
    getmsgmeth() {
      sessionStorage.setItem("sub2msgMobile", this.allmsg.mobile);
      sessionStorage.setItem("sub2msgName", this.allmsg.name);
      if (this.allmsg.id_no != "") {
        sessionStorage.setItem("sub2msgId_no", this.allmsg.id_no);
      }
      this.$router.push("/backtotwo");
    },
    getmsgmeth2() {
      //分享进入
      //分享进入
      if (this.isshare && !localStorage.getItem("userId")) {
        if (islogin()) {
        } else {
          this.$router.push("/downloadresume");
          return;
        }
      } else {
        this.$router.push({
          path: "evalpay",
          query: {
            msg: JSON.stringify({
              id: btoa(this.allmsg.id),
              paytype: 10,
              is_perfect: this.allmsg.is_perfect,
              tipmsg: "获取联系方式",
              share_phone: this.share_phone,
              isshare: this.isshare, //是否是分享进入
            }),
          },
        });
      }
    },
    /**获取年龄 */
    getAge(msg) {
      var birthday = new Date(msg.replace(/-/g, "/"));
      var d = new Date();
      var age =
        d.getFullYear() -
        birthday.getFullYear() -
        (d.getMonth() < birthday.getMonth() ||
        (d.getMonth() == birthday.getMonth() &&
          d.getDate() < birthday.getDate())
          ? 1
          : 0);
      return age;
    },
    selectshezhi(msg) {},
    jimeth(index) {
      this.skillindex = index;
      this.jiindex = index;
    },
  },
  destroyed() {
    sessionStorage.removeItem("sharemsg");
  },
};
</script>
<style scoped>
.fx {
  position: fixed;
  top: 50%;
  right: 0;
  font-size: 0.3rem;
  background-color: #ffffff;
  padding: 0.2rem 0.3rem;
  border: 1px solid #dbdcdf;
  border-right: none;
  border-radius: 0.1rem 0 0 0.1rem;
  box-shadow: 0 0 0.2rem #dbdcdf;
  color: rgb(57, 119, 246);
  font-weight: bold;
}
.moclaall {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.06rem;
}
.gxtime {
  color: #606266;
  font-size: 0.28rem;
  margin-top: 0.16rem;
  font-weight: 400;
}
.fujianall {
  display: flex;
  align-items: center;
}
.fujianclass {
  color: #ff6d20;
  margin-left: 0.1rem;
}
.timebackclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.02rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.48rem;
}
.jltwo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.1rem;
  margin-bottom: 0.26rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.48rem;
}
.alljingcla {
  margin-top: 0.14rem;
}
.henggang {
  height: 0.14rem;
  background-color: #f6f6f7;
  position: absolute;
  width: 100%;
  left: 0;
}
.tuijclass {
  text-align: center;
  font-size: 0.28rem;
  line-height: 0.33rem;
  font-weight: 400;
  color: #ffffff;
  position: relative;
  background-color: #ff6d20;
  padding: 0.14rem;
}
#gw_2 {
  margin-bottom: 0.06rem;
}
.scc > :last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.gw2_1 {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
.mocla {
  white-space: nowrap;
  font-size: 0.32rem;
  font-weight: 600;
  color: #ec5d33;
  line-height: 0.48rem;
}
.duana2 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0.29rem;
}
.ddiv1 {
  padding: 0.2rem 0.5rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff5f19;
  line-height: 0.4rem;
  background: #ffffff;
  border-radius: 0.4rem;
  border: 0.02rem solid #ff5f19;
  text-align: center;
  margin-bottom: 0.24rem;
  margin-right: 0.24rem;
}
.ddiv2 {
  padding: 0.2rem 0.5rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgb(51, 51, 51);
  line-height: 0.4rem;
  background: #ebebeb;
  border-radius: 0.4rem;
  border: 0.02rem solid #ebebeb;
  text-align: center;
  margin-bottom: 0.24rem;
  margin-right: 0.24rem;
}
.jin {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9d9d9d;
  line-height: 0.42rem;
  margin-top: 0.46rem;
  margin-bottom: 0.42rem;
}
.jin > :nth-child(2) {
  margin: 0 0.64rem;
}
.selcl {
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.45rem;
}
.selcl::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.08rem;
  background-color: red;
  margin-left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(270deg, #fc9c45 0%, #fe4e04 100%);
  border-radius: 0.06rem;
  margin-top: 0.1rem;
}
.selcl2::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.08rem;
  background-color: red;
  margin-left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 0.06rem;
  margin-top: 0.1rem;
}
.nomsgclass {
  height: 2rem;
}
.faqi {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: #ffffff;
  padding: 0.28rem 0;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #dfdfdf;
}
.faqi button {
  padding: 0.28rem 0;
  text-align: center;
  border: none;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  width: 90%;
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  border-radius: 0.16rem;
  margin-left: 5%;
}
.faqi2 {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: #ffffff;
  padding: 0.28rem 0;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #dfdfdf;
}
.faqi2 button {
  padding: 0.28rem 0;
  text-align: center;
  border: none;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  width: 90%;
  background: rgb(121, 121, 121);
  border-radius: 0.16rem;
  margin-left: 5%;
}

.jingcla {
  padding-top: 0.34rem;
  padding-bottom: 0.26rem;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dfdfdf;
}
.jingcla > :first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.32rem;
  font-weight: 500;
  color: #606266;
  line-height: 0.48rem;
}

.jingl {
  padding-top: 0.36rem;
  margin-top: 0.14rem;
}
.allgw {
  padding: 0.4rem 0;
  margin-top: 0.14rem;
}
.gw2 {
  margin: 0.16rem 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;

  font-size: 0.32rem;

  font-weight: 500;
  color: #606266;
  line-height: 0.48rem;
}
.gw3 {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #909399;
  line-height: 0.48rem;
}
.gw1 {
  display: flex;
  align-items: center;
  font-size: 0.36rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #303133;
  line-height: 0.48rem;
}
.gw1 > :first-child {
  width: 0.16rem;
  height: 0.16rem;
  border-radius: 50%;
  background-color: #ff4900;
  margin-right: 0.24rem;
}
.zhicla {
  padding: 0.35rem 0;
  padding: 0.33rem 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dfdfdf;
}
.imgcc {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: #313131;
  line-height: 0.48rem;
}
.imgcc > div {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}
.imgcc img {
  width: 0.3rem;
  margin-right: 0.17rem;
}

.zhicla > :first-child {
  font-size: 0.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.4rem;
}
.zhicla > :nth-child(2) {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #313131;
  line-height: 0.48rem;
  display: flex;
  align-items: center;
  margin-top: 0.36rem;
}
.mmc {
  margin: 0 0.38rem;
}
.userclass {
  position: relative;
}
.userclass .imgsex {
  width: 1.27rem;
  height: 1.27rem;
  border-radius: 50%;
}
.pms_3 {
  position: absolute;
  width: 1.08rem;
  bottom: -0.1rem;
  left: 50%;
  transform: translateX(-50%);
}
.phmsg {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.33rem;
  margin-top: 0.16rem;
  margin-bottom: 0.42rem;
}
.phmsg > :nth-child(2) {
  margin: 0 0.08rem;
}
.pms {
  display: flex;
  align-items: center;
  position: relative;
}
.pms > :first-child {
  font-size: 0.4rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.48rem;
}
.pms_1 {
  padding: 0.04rem 0.2rem;
  background: #ff9858;
  margin: 0 0.18rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.33rem;
}
.pms_2 {
  width: 0.45rem;
  position: relative;
  margin-left: 0.1rem;
}

.classmsg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.46rem 0;
}
.sz {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}

.faqi3 {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: #ffffff;
  padding: 0.28rem 0;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #dfdfdf;
}
.faqi3 button {
  padding: 0.28rem 0;
  text-align: center;
  border: none;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  width: 90%;
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  border-radius: 0.16rem;
  margin-left: 5%;
}
.faqi {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: #ffffff;
  padding: 0.28rem 0;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #dfdfdf;
}
.faqi button {
  padding: 0.28rem 0;
  text-align: center;
  border: none;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  width: 30%;
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  border-radius: 0.16rem;
  margin-left: 2.4%;
}
.faqi > :nth-child(2),
.faqi > :nth-child(3) {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 0.42rem;
  border-radius: 0.16rem;
  border: none;
  padding: 0.25rem 0;
  color: #ff5f19;
  background: #ffffff;
  border-radius: 0.16rem;
  border: 0.03rem solid #ff5f19;
}
</style>
