import base from "./base"
function s_resume(tourl="") {
    let sharemm = JSON.parse(sessionStorage.getItem("sharemsg"));
    let urlmsg = "?login_submsg=1&";
    Object.keys(sharemm).forEach(e => {
        if (e == "share_phone" || e == "sub_code" || e == "urlsharees"||e=="id") {
            urlmsg += e + "=" + sharemm[e] + "&";
        }
    })
    window.location.href = encodeURI(base.url + "/" + tourl + urlmsg);
}
export default s_resume;